import React, { useContext, useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { useTransition, animated, useSpring, useChain } from "react-spring"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import useIsTablet from "../../hooks/useIsTablet"
import Contents from "./Contents"
import MobileContents from "./MobileContents"
import { remCalc } from "../../styles/utils"

const StyledMenu = styled.div`
  width: 100%;
  height: ${({ openMenu }) => (openMenu ? "100vh" : "0")};
  position: fixed;
  /* background-color: ${({ theme }) => theme.color.foreword}; */
  z-index: 400;

  & .contents_container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    background: black;
    & .foreword_link {
      cursor: pointer;
      text-align: center;
      z-index: 10;
      color: white;

      & * {
        color: white;
      }
      & h2 {
        font-size: ${remCalc(104)};
        color: white;

        position: relative;
        /* &:after {
          content: "";
          bottom: 0;
          width: 100%;
          left: 0;
          background: white;
          height: 2px;
          position: absolute;
        } */
      }
      & p {
        font-size: ${remCalc(20)};
        color: white;
      }
    }
  }
  & .contents_box-left {
    padding: 5rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    position: relative;
    height: 100%;
    background-color: black;
    & .foreword_arrow {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      & svg {
        width: ${remCalc(50)};
        height: ${remCalc(50)};
      }
      & p {
        color: white;
        margin-right: 0.8rem;
      }
    }
    & h2 {
      font-size: 8vw;
      font-family: ${({ theme }) => theme.typography.type.primary};
      color: white;
      position: relative;
      z-index: 2;
    }
    & .contents_triangle {
      height: 0;
      width: 0;
      border-top: ${({ screenHeight }) =>
        `${screenHeight}px solid transparent`};
      border-bottom: ${({ screenHeight }) => `${screenHeight}px solid green`};
      position: absolute;
      border-left: ${({ screenHeight }) =>
        `${screenHeight}px  solid transparent`};
      z-index: 1;
      bottom: 0;
    }
  }
  & .contents_box-right {
    width: 50%;
    position: relative;
    height: 100%;
    z-index: 1;
    right: 0;
    top: 0;

    &-inner {
      width: 100%;
      height: 100%;
      position: relative;
      float: left;
      background-color: #f1f5f6;
    }
  }
`

const index = () => {
  const state = useContext(GlobalStateContext)
  const [animateMenu, setAnimateMenu] = useState(false)
  const [screenHeight, setHeight] = useState(100)
  const isTablet = useIsTablet()

  const transition = useTransition(state.openMenu, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    setHeight(window.innerWidth / 2)
  }, [])

  useEffect(() => {
    if (state.openMenu) {
      setAnimateMenu(true)
    } else {
      setTimeout(() => {
        setAnimateMenu(false)
      }, 500)
    }
  }, [state.openMenu])

  return (
    <>
      <StyledMenu
        data-scroll
        data-scroll-sticky
        data-scroll-target="#container"
        screenHeight={screenHeight}
        openMenu={animateMenu}
      >
        {transition.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} style={{ ...props, height: "100%" }}>
                {isTablet ? (
                  <MobileContents />
                ) : (
                  <Contents menuOpen={state.openMenu} />
                )}
              </animated.div>
            )
        )}
      </StyledMenu>
    </>
  )
}

export default index
