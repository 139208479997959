import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import Logo from "../images/svgs/Logo.jsx"
import Navburger from "./Navburger"
import { GlobalStateContext } from "../context/GlobalContextProvider"

const Header = ({ location }) => {
  const state = useContext(GlobalStateContext)

  return (
    <header
      data-scroll
      data-scroll-sticky
      data-scroll-target="#container"
      style={{
        width: `100%`,
        position: `fixed`,
        top: 0,
        left: 0,
        zIndex: 500,
      }}
      className={state.openMenu ? "menu_open" : ""}
    >
      <div className="header">
        <div className="header_logo">
          <Link to="/">
            <Logo />{" "}
            {location.length > 1 ? <p>Landsec contribution report</p> : ""}
          </Link>
        </div>
        <div className="burger_container">
          <Navburger />{" "}
          {state.openMenu ? (
            <p className="menu_open desktop">Close</p>
          ) : (
            <p className="menu_closed desktop">Menu</p>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
