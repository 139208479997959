import styled, { createGlobalStyle, css } from "styled-components"
import {
  remCalc,
  pageMargins,
  absoluteMarginsRight,
  absoluteMargins,
} from "./utils"
import { color, typography } from "./variables"
import { animated } from "react-spring"
import { scroll } from "./scroll"
import reset from "./reset"
import { bounceArrow } from "./animation"

export const textStyles = css`
  .text-h0 {
    font-size: 40px;
    line-height: 56px;
    @media only screen and (min-width: 640px) {
      font-size: 64px;
      line-height: 83.2px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: ${remCalc(80)};
      line-height: ${remCalc(96)};
      letter-spacing: 0.4px;
    }
  }
  .text-h3 {
    font-size: 40px;
    line-height: 56px;
    font-family: ${typography.type.primary};
    @media only screen and (min-width: 640px) {
      font-size: 64px;
      line-height: 83.2px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: ${remCalc(56)};
      line-height: ${remCalc(64)};
      letter-spacing: -1px;
    }
  }
  .text-xs {
    font-size: 14px;
    line-height: 21px;
    @media only screen and (min-width: 640px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: ${remCalc(18)};
      line-height: ${remCalc(27)};
    }
  }
  .text-s {
    font-size: 16px;
    line-height: 24px;
    @media only screen and (min-width: 640px) {
      font-size: 20px;
      line-height: 30px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: ${remCalc(20)};
      line-height: ${remCalc(30)};
    }
  }
  .text-h5 {
    font-size: 24px;
    line-height: 32px;
    @media only screen and (min-width: 640px) {
      font-size: 40px;
      line-height: 58px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: ${remCalc(56)};
      line-height: ${remCalc(68)};
    }
  }
`

export const bodyStyles = css`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #444444;
  font-size: 1rem;
  color: "black";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  --content-height: 100vh;

  --covid-header-color: #da3c2a;
  --uk-overview-header-color: #c04b52;
  --primary-font: '"Landsec Brown", "serif"';
  --economic-header-color: * {
    box-sizing: border-box;
  }

  main {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${typography.weight.regular};
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: ${remCalc(96)};
    font-family: ${typography.type.primary};
    line-height: ${remCalc(114)};
    margin: 0 0 2rem 0;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      font-size: ${remCalc(60)};
      line-height: ${remCalc(70)};
    }
    @media only screen and (max-width: 640px) {
      font-size: 40px;
      line-height: 44px;
    }
  }

  p {
    font-size: 1.25rem;
    font-family: ${typography.type.primary};
  }

  .m-w-100 {
    @media only screen and (max-width: 640px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .t-w-100 {
    @media only screen and (max-width: 1024px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .t-align-l {
    text-align: left;
  }
  .t-align-r {
    text-align: right;
  }
  .t-align-c {
    text-align: center;
  }
  .ma-auto {
    margin: 0 auto;
    @media only screen and (max-width: 640px) {
      padding-top: 0 !important;
    }
  }
  .font-primary {
    font-family: ${typography.type.primary};
  }
  .font-secondary {
    font-family: ${typography.type.secondary};
  }

  .text-animated {
    overflow: hidden;
    & .line-inner {
      display: inline-block;
      transform: translateY(100%);
      &.is-animating {
        transform: translateY(0);
      }
      & > span {
        display: inline-block;
        transform: translateY(100%);
      }
    }
  }

  // Beware - this is a really, really smart global rule. Remove if you no longer have hair to lose.
  .align-center {
    display: flex !important;
    align-items: center !important;
  }

  .paragraph-transform {
    overflow: hidden;
  }

  .contact_name {
    position: absolute;
    bottom: 56px;
    left: 0;
    & p {
      width: 100%;
    }

    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      ${absoluteMargins};
    }
    @media only screen and (max-width: 640px) {
      position: relative;
      margin-bottom: 4rem;
    }
  }
  .contact_landsec-link {
    position: absolute;
    bottom: 56px;
    right: 0;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      ${absoluteMarginsRight};
    }
    @media only screen and (max-width: 640px) {
      ${absoluteMargins};
    }
  }

  h2 {
    margin-bottom: 2.8125rem;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 1.8rem;
    }
  }
  .contact_large-para {
    font-size: 1.5rem !important;
    line-height: 33px !important;
    @media only screen and (max-width: 1024px) {
      font-size: 20px;
      line-height: 27px;
    }
  }
  .contact_landsec-link {
    font-size: 20px;
    font-family: ${typography.type.primary};
    line-height: 2.125rem;
    @media only screen and (max-width: 1024px) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  button,
  input,
  textarea,
  select {
    outline: none;
    font-family: ${typography.type.primary};
  }

  sub,
  sup {
    font-size: 0.8em;
  }

  sub {
    bottom: -0.2em;
  }

  sup {
    top: -0.2em;
  }

  b,
  em {
    font-weight: ${typography.weight.bold};
  }

  hr {
    border: none;
    border-top: 1px solid ${color.darker};
    clear: both;
    margin-bottom: 1.25rem;
  }

  & header {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 101;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;

    &.header-black {
      filter: invert(1);
    }
    &.menu_open {
      background: transparent;
      @media only screen and (max-width: 1024px) {
        & .header_logo {
          filter: invert(1);
        }
      }
    }

    & .header {
      width: 100%;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s ease;
      ${pageMargins};

      @media only screen and (min-width: 1024px) {
        padding: 0 1.5rem;
      }
      &.invert {
        filter: invert(1);
      }
    }
    & .header_logo {
      height: ${remCalc(49)};
      float: left;
      & a {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
        & p {
          width: 7rem;
          font-size: ${remCalc(15)};
          line-height: ${remCalc(17)};
          margin-left: 0.5rem;
        }
      }
    }

    &.menu-open {
      z-index: -1;
    }
  }
  & .map__svgs {
    position: fixed;
    right: 5rem;
    top: -3vh;
    width: 45%;
    height: calc(558px * 1.2);
    @media only screen and (max-width: 1024px) {
      display: none;
    }
    & svg {
      display: block;
      g {
        position: relative;
      }
    }
  }
  & .grey_padding-element {
    & p {
      font-family: ${typography.type.secondary};
      line-height: 1.5rem;
      font-size: 1rem;
      padding: 1.5rem;
      background: #eceeee;
    }
    &.social {
      @media only screen and (min-width: 1024px) {}
    }
  }
  .svg-label {
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
    display: none;
    transform: translate(0, -45px);

    &.is-showing {
      opacity: 1;
      display: inline-block;
      z-index: 100;
    }
  }
  .economic_bottom-section {
    position: relative;
    margin: 0 auto;
    z-index: 300;
    ${"" /* background: #f1f5f6; */}
  }
  .label-active {
    ${"" /* background-color: rgba(0, 0, 0, 0.2); */}
    transition: background-color 0.3s ease;
    & p {
      color: #ea3ef7;
    }
  }
  .economic-item {
    transition: background-color 0.3s ease;
  }
  .mobile-show {
    display: inline-block;
    @media only screen and (min-width: 640px) {
      display: none;
    }
  }

  .svg-target {
    pointer-events: bounding-box;
    z-index: 2;
    transition: transform 0.3s ease;
    cursor: pointer;
    &:hover {
      & path {
        fill: #ea3ef7;
      }
    }
  }
  & .map__zoom {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;
  }
  & .pins {
    z-index: 2;
    bottom: 7.5%;
  }

  .default-border {
    border-bottom: 1px solid #a6b6ba;
  }

  & .contents_row {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 2.7vh 0;
    will-change: transform, opacity;
    cursor: pointer;
    border-bottom: 1px solid #a6b6ba;
    font-family: ${typography.type.primary};
    &:last-of-type {
      border-bottom: 1px solid transparent;
    }
    & h4 {
      font-weight: normal;
      line-height: 22px;
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      padding: 2vh 0;
      h4 {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 640px) {
      padding: 0.9vh 0;
      & h4 {
        font-weight: normal;
        line-height: 22px;
      }
    }
    @media only screen and (max-width: 640px) {
      & h4 {
        font-weight: normal;
        line-height: 18px;
        font-size: 15px;
      }
    }

    @media only screen and (max-height: ) &:hover {
      text-decoration: underline;
    }
    & h3 {
      font-size: 1.5rem;
    }
    &.contents_title {
      border-bottom: 1px solid transparent;
      margin-top: 2rem;
      &:hover {
        text-decoration: unset;
      }
      & * {
        font-weight: bold;
      }
      @media only screen and (max-width: 1024px) {
        margin-top: 2vh;
      }
    }
    & .menu_title {
      font-size: 2.5rem;
      &:hover {
        cursor: initial;
      }
      &-main {
        font-size: 2.5rem;
        &:hover {
          cursor: initial;
        }
        @media only screen and (max-width: 640px) {
          font-size: 24px;
        }
      }
    }

    & .contents_square {
      width: 48%;
      position: relative;

      &-national {
        background: blue;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
      &-economic {
        background: pink;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
  }

  .mobile-home-hero {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    & .logo {
      width: 80%;
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    & .mobile-home-bg {
      object-fit: cover;
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .align-flex-start {
    align-items: flex-start;
    @media only screen and (max-width: 640px) {
      align-items: center;
    }
  }

  .p-bottom-0 {
    padding-bottom: 0;
  }
  .p-top-1 {
    padding-top: 0;
  }

  .m-top-0 {
    margin-top: 0;
  }
  .m-bottom-0 {
    margin-bottom: 0;
  }

  .alt-caption {
    display: inline-block;
    position: relative;

    &:after {
      right: -24px;
      top: 4px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(133, 132, 132, 0);
      border-bottom-color: #000000;
      border-width: 6px;
      /* margin-top: -2px; */
      background: 0 0;
    }
  }

  .landsec_link {
    text-decoration: underline;
    font-weight: bold;
    margin-top: 2rem;
    color: black;
    font-family: ${typography.type.primary};
    transition: opacity 0.3s ease;
    opacity: 1;
    &.link_in {
      opacity: 0;
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1024px) {
      text-decoration: none !important;

      border: 1px solid #a6b6ba;
      margin-top: 1rem;
      color: black;
      padding: 1.5rem 0;
      width: 100%;
      text-align: center;
    }
    @media only screen and (max-width: 640px) {
      padding: 4.5vw 0;
    }
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.25rem;
    justify-content: center;
    align-content: center;
    & .full-width {
      grid-column: col-start / span 12;
    }
  }
  .align-self {
    align-self: center;
  }
  .dividing_line {
    position: absolute;
    width: 100%;
    ${pageMargins};
    height: 1px;
    left: 0;
    bottom: 0;
    &-inner {
      background-color: #a6b6ba;
      position: relative;
      width: 100%;
      height: 1px;
      margin: 0 auto;
    }
  }

  .image_full-width {
    width: 100%;

    position: relative;
  }
  .burger_container {
    display: flex;
    align-items: center;
    cursor: pointer;
    & p {
      font-size: 1rem;
      margin-left: 0.8rem;
    }
    & .menu_open {
      color: black;
    }
    & .menu_closed {
      color: white;
    }
  }

  & .lottie {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;

    @media only screen and (max-width: 1024px) {
      height: 666px;
      width: 333px;
      bottom: 0;
      top: unset;

      right: 0;
    }

    @media only screen and (max-width: 640px) {
      height: 495px;
      width: 245px;
    }
  }

  .mobile {
    display: inline-block;
    @media only screen and (min-width: 640px) {
      display: none;
    }
  }
  .tablet {
    display: inline-block;
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
  .desktop {
    display: none;
    @media only screen and (min-width: 640px) {
      display: inline-block;
    }
  }

  .m-ma-t-0 {
    @media only screen and (max-width: 640px) {
      margin-top: 0;
    }
  }

  //animation classes

  .bounceArrow {
    animation-name: ${bounceArrow};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .red {
    color: #da3c2a;
    & * {
      color: #da3c2a;
    }
    & svg {
      & * {
        fill: #da3c2a;
      }
    }
  }

  .section-padding {
    ${pageMargins};
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: relative;
    @media only screen and (min-width: 1024px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }

  .page_title-subtitle {
    & .title {
      grid-column: 1/6;
      align-self: center;
      font-size: ${remCalc(56)};
      line-height: ${remCalc(68)};
      font-family: ${typography.type.primary};
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        grid-column: 1/6;
        font-size: 48px;
        line-height: 60px;
      }
    }
    & .subtitle {
      font-family: ${typography.type.primary};
      grid-column: 7/13;
      align-self: center;
      font-size: 1.25rem;
      line-height: ${remCalc(30)};
    }
    @media only screen and (max-width: 640px) {
      display: flex;
      flex-direction: column;
      & .title {
        font-size: 2rem;
        line-height: 2rem;
        align-self: start;
      }
      & .subtitle {
        font-size: 18px;
        line-height: 28px;
        font-family: ${typography.type.secondary};
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    p {
      font-size: 1rem;
      letter-spacing: 0.4px;
    }
  }
`

export const PageContainer = styled.div`
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  margin: 0 auto;
  /* min-height: 100vh; */
`

export const GlobalStyle = createGlobalStyle`
${reset}



 body {
   ${bodyStyles}
   ${textStyles}
   background-color: #F1F5F6;
 }

 html {
  font-size: 100%;
}

@media only screen and (min-width: 1700px) and (max-width: 1800px) {
  html {
    font-size: 120%;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2200px) {
  html {
    font-size: 130%;
  }
}

@media only screen and (min-width: 2200px) and (max-width: 3000px) {
  html {
    font-size: 150%;
  }
}

@media only screen and (min-width: 3000px) and (max-width: 4000px) {
  html {
    font-size: 220%;
  }
}

@media only screen and (min-width: 4000px) and (max-width: 4500px) {
  html {
    font-size: 250%;
  }
}

@media only screen and (min-width: 4500px) {
  html {
    font-size: 290%;
  }
}
`
