/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
//modules
import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Scroll from "../components/locomotiveScroll"
import { ThemeProvider } from "styled-components"
import { useTransition, animated } from "react-spring"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
//components
import { color, typography } from "../styles/variables"
import { GlobalStyle, LayoutContainer } from "../styles/global"
import GlobalContextProvider, {
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import Header from "../components/Header"
import Menu from "../components/Menu"
import "../components/layout.css"
import "../components/locomotive-scroll.css"
import Pins from "../images/svgs/Pins"

import "../styles/fonts.css"
import Map from "../images/svg/Map-final.svg"

function keyExistsInArray(key, arr) {
  return arr.some(function (el) {
    return el.key === key
  })
}

const Layout = ({ location, children }) => {
  const visitedRoutes = useRef([])
  const [colliding, setColliding] = useState(false)

  const data = useStaticQuery(graphql`
    query siteTitleQueryAndSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const theme = {
    color,
    typography,
  }

  const doElsCollide = el1 => {
    return (
      el1.getBoundingClientRect().top < 20 &&
      el1.getBoundingClientRect().bottom > 20
    )
  }

  const isColliding = () => {
    const whiteBgs = [...document.querySelectorAll(".white-bg")]
    let collide = false
    whiteBgs.map(item => {
      if (doElsCollide(item)) {
        collide = true
      }
    })
    return collide
  }

  const transitions = useTransition(location, location.pathname, {
    from: {
      position: "absolute",
      opacity: 0.01,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true,
    reset: true,
  })
  const exists = keyExistsInArray(children.key, visitedRoutes.current)
  if (!exists) {
    visitedRoutes.current.push(children)
  }

  useEffect(() => {}, [])

  return (
    <>
      <GlobalContextProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />

          <Header
            location={location.pathname}
            siteTitle={data.site.siteMetadata?.title || `Title`}
          />
          {location.pathname.includes("economic") ? (
            <div
              data-scroll
              data-scroll-sticky
              className="fixed_svg"
              data-scroll-target="#container"
              style={{
                width: `100%`,
                position: `fixed`,
                top: 0,
                right: 0,
                zIndex: 10,
              }}
            >
              <div className="map__svgs">
                <Map className="map__zoom" />
              </div>
            </div>
          ) : (
            ""
          )}

          <Menu />
          {/* Here we pass the callbacks to the component. Anything that impacts the innerHeight, for example: Font Loaded */}
          <Scroll callbacks={location} />

          <div id="container" className="layout-container">
            {children}
          </div>
        </ThemeProvider>
      </GlobalContextProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
