import React, { useEffect, useState } from "react"

import lottie from "lottie-web"

const Lottie = ({ animationData }) => {
  useEffect(() => {
    const params = {
      container: document.querySelector(".lottie"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    }

    lottie.loadAnimation(params)
  }, [])

  return <div className="lottie"></div>
}

export default Lottie
