import React, { useRef, useState, useContext, useEffect } from "react"
import styled from "styled-components"
import Arrow from "../../images/svgs/NavigationArrow"
import * as easings from "d3-ease"
import { Link, navigate } from "gatsby"
import {
  useTrail,
  animated,
  useSpring,
  useChain,
  config,
  useTransition,
} from "react-spring"
import usePrevious from "../../hooks/usePrevious"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { toggleClass } from "../../../lib/helperFunctions"
import { typography } from "../../styles/variables"
import Lottie from "../Lottie"
import useIsTablet from "../../hooks/useIsTablet"

import animationData from "../../animations/DESKTOP_MOBILE/02-Menu_Halfwidth.json"

const StyledContents = styled(animated.div)`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  & > h2 {
    font-size: 2.5rem;
  }

  & h3 {
    font-size: 2.5rem;

    width: 100%;
  }
  & .contents_top {
    display: flex;
    align-items: center;
    justify-contents: space-between;
    font-size: 2.5rem;
  }
  & .menu_close {
    transform: rotate(45deg);
    transition: transform 0.3s ease;
    width: 3rem;
    height: 3rem;
    background: none;
    outline: none;
    border: none;
    &:hover {
      transform: rotate(135deg);
    }
  }
  & h4 {
    font-size: 1.5rem;
    font-family: ${typography.type.primary};

    font-weight: bold;
  }
`

const Contents = ({ menuOpen }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const [open, setOpen] = useState(false)
  const isTablet = useIsTablet()

  const [urlPath, setPath] = useState("")
  const prevMounted = usePrevious(isAnimating)
  const rightBoxRef = useRef()
  const foreWordRef = useRef()

  const topRowRef = useRef()
  const rowRef = useRef()
  const { isAnimating } = state
  const [show, set] = useState(false)
  const transitions = useTransition(show, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 2000 },
  })

  const boxCurrent = !rightBoxRef.current
    ? rightBoxRef
    : { current: rightBoxRef.current }
  const foreWordCurrent = !foreWordRef.current
    ? foreWordRef
    : { current: foreWordRef.current }

  const rowCurrent = !rowRef.current ? rowRef : { current: rowRef.current }

  const rows = [
    { text: "Contents", path: "" },
    {
      text: "Landsec contribution overview",
      path: "landsec-contribution-overview",
    },
    { text: "Total economic contribution", path: "economic-contribution" },
    { text: "Our response to Covid-19", path: "covid-19-response" },
    { text: "Spotlight on: Jobs", path: "jobs" },
    {
      text: "Spotlight on: Environmental sustainability",
      path: "sustainability",
    },
    {
      text: "Spotlight on: Social sustainability",
      path: "social-sustainability",
    },
    { text: "landsec", path: "" },
  ]

  const rightBox = useTransition(isAnimating, null, {
    ref: rightBoxRef,
    from: { height: "0%", width: "0.3%" },
    enter: () => [{ height: "100%" }, { width: "100%" }],
    leave: [{ width: "0.3%" }, { height: "0%" }],
    config: isAnimating ? easings.easeQuadIn(2.0) : { duration: 200 },
    onDestroyed: () => {
      if (
        (!isAnimating && !urlPath.length) ||
        (!isAnimating && window.location.pathname === `/${urlPath}`)
      ) {
        dispatch({ type: "TOGGLE_MENU" })
        if (window.location.pathname.includes("economic-contribution")) {
          document.querySelector("header").classList.add("header-black")
        }
      } else if (urlPath.length && window.location.pathname !== `/${urlPath}`) {
        navigate(`/${urlPath}`)
        setPath("")
        if (window.location.pathname.includes("economic-contribution")) {
          document.querySelector("header").classList.add("header-black")
        }
      }
    },
  })

  const foreword = useTransition(isAnimating, null, {
    ref: foreWordRef,
    from: { opacity: 0, transform: "translateY(50px)", x: 100 },
    enter: { opacity: 1, transform: "translateY(0px)", x: 0 },
    leave: { opacity: 0, transform: "translateY(50px)", x: 100 },
    config: isAnimating ? easings.easeQuadIn(4.0) : { duration: 200 },
    // reverse: !isAnimating,
  })

  // const topRowAnimation = useTransition(
  //   isAnimating ? topRows : [],
  //   item => item.text,
  //   {
  //     ref: topRowRef,
  //     from: { opacity: 0, transform: "translateX(50px)" },
  //     enter: { opacity: 1, transform: "translateX(0px)" },
  //     leave: { opacity: 0, transform: "translateX(50px)" },
  //     unique: true,
  //     trail: isAnimating ? 50 : 25,
  //     config: easings.easeQuadIn(4.0),

  //     // reverse: !isAnimating,
  //   }
  // )

  const svgs = useTransition(isAnimating, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 2000 },
  })

  const rowAnimation = useTransition(
    isAnimating ? rows : [],
    item => item.text,
    {
      ref: rowRef,
      from: { opacity: 0, transform: "translateX(50px)" },
      enter: { opacity: 1, transform: "translateX(0px)" },
      leave: { opacity: 0, transform: "translateX(50px)" },
      unique: true,
      trail: isAnimating ? 50 : 40,
      config: easings.easeQuadIn(4.0),

      // reverse: !isAnimating,
    }
  )

  useChain(
    isAnimating
      ? [boxCurrent, rowCurrent, foreWordCurrent]
      : [foreWordCurrent, rowCurrent, boxCurrent],
    isAnimating ? [0.4, 1.3, 1.5] : [0, 0.4, 0.8]
  )

  useEffect(() => {
    dispatch({ type: "TOGGLE_ANIMATING" })
  }, [])

  useEffect(() => {
    if (urlPath.length) {
      dispatch({ type: "TOGGLE_ANIMATING" })
    }
  }, [urlPath])

  return (
    <div className="contents_container">
      <div className="contents_box-left">
        {svgs.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} style={props}>
                <Lottie animationData={animationData} />
              </animated.div>
            )
        )}

        {foreword.map(({ item, key, props }) =>
          item ? (
            <animated.div
              onClick={() => setPath("foreword")}
              key={key}
              style={props}
              className="foreword_link"
            >
              <h2>Foreword</h2>
              <p>A word from our Chief Executive</p>
            </animated.div>
          ) : null
        )}
        <div onClick={() => setPath("foreword")} className="foreword_arrow">
          <p>Read</p>
          <Arrow />
        </div>
      </div>

      <div className="contents_box-right">
        {rightBox.map(({ item, key, props: { height, width } }) =>
          item ? (
            <animated.div
              className="contents_box-right-inner"
              style={{ width, height }}
              key={key}
            >
              <StyledContents>
                {rowAnimation.map(
                  ({ item, key, props: { opacity, transform } }) =>
                    item && item.text !== "landsec" ? (
                      <animated.div
                        key={key}
                        style={{ opacity, transform }}
                        onClick={() =>
                          item.path.length ? setPath(item.path) : null
                        }
                        className={`contents_row ${
                          !item.path.length ? "contents_title" : ""
                        }`}
                        onMouseEnter={() =>
                          item.path.length
                            ? toggleClass(`.link-arrow-${item.path}`)
                            : ""
                        }
                        onMouseLeave={() =>
                          item.path.length
                            ? toggleClass(`.link-arrow-${item.path}`)
                            : ""
                        }
                      >
                        {item.path.length ? (
                          <React.Fragment>
                            <h4>{item.text}</h4>
                            <Arrow className={`link-arrow-${item.path}`} />
                          </React.Fragment>
                        ) : (
                          <h4 className="menu_title">{item.text}</h4>
                        )}
                      </animated.div>
                    ) : item && item.text === "landsec" ? (
                      <animated.a
                        key={key}
                        style={{ opacity, transform }}
                        href="https://landsec.com"
                      >
                        <p className="landsec_link">Return to landsec.com</p>
                      </animated.a>
                    ) : null
                )}
              </StyledContents>
            </animated.div>
          ) : null
        )}
      </div>
    </div>
  )
}

export default Contents
