import gsap, { Elastic, Power4 } from "gsap"

export const cubic = {
  start: 0,
  bezier: function (p0, p1, p2, p3) {
    return cubic.polyBez([p0, p1], [p2, p3])
  },
  polyBez: function (p1, p2) {
    let A = [null, null],
      B = [null, null],
      C = [null, null],
      bezCoOrd = function (t, ax) {
        ;(C[ax] = 3 * p1[ax]),
          (B[ax] = 3 * (p2[ax] - p1[ax]) - C[ax]),
          (A[ax] = 1 - C[ax] - B[ax])
        return t * (C[ax] + t * (B[ax] + t * A[ax]))
      },
      xDeriv = function (t) {
        return C[0] + t * (2 * B[0] + 3 * A[0] * t)
      },
      xForT = function (t) {
        let x = t,
          i = 0,
          z
        while (++i < 14) {
          z = bezCoOrd(x, 0) - t
          if (Math.abs(z) < 1e-3) break
          x -= z / xDeriv(x)
        }
        return x
      }
    return function (t) {
      return bezCoOrd(xForT(t), 1)
    }
  },
}

export const animateLetters = (letters, initDelay, delay, duration) => {
  letters.map((letter, position) => {
    if (!letter.innerText.trim().length) {
      letter.innerText = "i"
      letter.style.opacity = 0
    }

    gsap.to(letter, duration, {
      ease: Power4.easeOut,
      delay: position * delay + initDelay,
      y: 0,
    })
  })
}

export const elasticScaleIn = ({ selector, delay, duration }) => {
  gsap.set(selector, {
    scale: 0,
    opacity: 0,
  })
  gsap.to(selector, duration, {
    opacity: 1,
    scale: 1,
    delay,
    ease: Elastic.easeOut.config(1.2, 0.4),
  })
}

export const toggleClass = name => {
  const elem = document.querySelector(name)
  elem.classList.toggle("bounceArrow")
}

export const animateParagraph = () => {
  const paras = [...document.querySelectorAll(".paragraph-transform")]
  if (paras) {
    paras.map(para => {
      const span = para.querySelector(".line-inner")
      span.style.transitionDelay = `${para.dataset.delay}s`
      span.style.transitionDuration = `${para.dataset.duration}s`
      para.classList.add("is-animating")
    })
  }
}

export const hideShowLabel = e => {
  const target = e.currentTarget
  const id = target.dataset.id
  const label = document.querySelector(`.${id}`)

  if (label.classList.contains("is-showing")) {
    label.classList.remove("is-showing")
  } else {
    label.classList.add("is-showing")
  }
}

export const scrollToLabel = e => {
  const target = e.currentTarget
  const id = target.dataset.id
  const label = document.querySelector(`#${id}`)
  const items = [...document.querySelectorAll(".economic-item")]
  if (label) {
    label.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    })
  }
  items.map(item => {
    if (item.classList.contains("label-active")) {
      item.classList.remove("label-active")
    }
  })
  label.classList.add("label-active")
}

export const addSvgListeners = () => {
  const hotspots = [...document.querySelectorAll(".svg-target")]
  if (hotspots.length) {
    hotspots.map(s => {
      //mouse enter the hot spots event listener
      s.addEventListener("mouseenter", e => {
        hideShowLabel(e)
      })

      s.addEventListener("touchstart", e => {
        hideShowLabel(e)
      })
      //mouse leaves the hot spots event listener
      s.addEventListener("mouseleave", e => {
        hideShowLabel(e)
      })
      s.addEventListener("touchend", e => {
        hideShowLabel(e)
      })
    })
  }
}

export const trans = x => `translateX(${x}px)`
