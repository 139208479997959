import { css } from "styled-components"
import { respondTo } from "./respondTo"

// Global style variables

export const remCalc = size => {
  let remSize
  if (Number.isInteger(size)) {
    remSize = `${size / 16}rem`
  }
  if (Array.isArray(size)) {
    remSize = ""
    size.map(px => (remSize += `${px / 16}rem `))
  }

  return remSize
}

export const pageMargin = "5.55555"

export const pageMargins = css`
  padding-left: 15px;
  padding-right: 15px;

  @media only screen and (min-width: 640px) {
    padding-left: ${pageMargin * 1}%;
    padding-right: ${pageMargin * 1}%;
  }
  @media only screen and (min-width: 1024px) {
    padding-left: ${pageMargin * 1.6}%;
    padding-right: ${pageMargin * 1.6}%;
  }
  @media only screen and (min-width: 1400px) {
    padding-left: ${pageMargin * 1.5}%;
    padding-right: ${pageMargin * 1.5}%;
  } ;
`

//x = 100vw
//p = 5
//width = x - 2p
//width * 0.4

export const responsiveFooterHeight = css`
  --xsWidth: calc(100vw - 30px);
  --sWidth: calc(100vw - ${pageMargin * 2}vw);
  --mWidth: calc(100vw - ${pageMargin * 4}vw);
  --lWidth: calc(100vw - ${pageMargin * 3}vw);
  height: calc(var(--xsWidth) * 0.49);
  @media only screen and (min-width: 640px) {
    height: calc(var(--sWidth) * 0.395);
  }
  @media only screen and (min-width: 1024px) {
    height: calc(var(--mWidth) * 0.38);
  }
  @media only screen and (min-width: 1400px) {
    height: calc(var(--lWidth) * 0.38);
  }
`

export const absoluteMargins = css`
  left: 15px;

  @media only screen and (min-width: 640px) {
    left: ${pageMargin * 1}%;
  }
  @media only screen and (min-width: 1024px) {
    left: ${pageMargin * 1.6}%;
  }
  @media only screen and (min-width: 1400px) {
    left: ${pageMargin * 1.5}%;
  } ;
`

export const absoluteMarginsRight = css`
  right: 15px;

  @media only screen and (min-width: 640px) {
    right: ${pageMargin * 1}%;
  }
  @media only screen and (min-width: 1024px) {
    right: ${pageMargin * 1.6}%;
  }
  @media only screen and (min-width: 1400px) {
    right: ${pageMargin * 1.5}%;
  } ;
`

export const Grid = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.25rem;
`

export const sectionCss = css`
  width: 100%;
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const flexCentre = css`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
