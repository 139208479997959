import { useEffect, useState, useContext } from "react"
import usePrevious from "../hooks/usePrevious"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from "locomotive-scroll"

import { scroll } from "../theme"

const Scroll = callbacks => {
  const dispatch = useContext(GlobalDispatchContext)
  const scrollToLabel = (e, scroll) => {
    const target = e.currentTarget
    const id = target.dataset.id
    const label = document.querySelector(`#${id}`)
    const items = [...document.querySelectorAll(".economic-item")]
    if (label) {
      scroll.scrollTo(label, { offset: -100 })
    }
    items.map(item => {
      if (item.classList.contains("label-active")) {
        item.classList.remove("label-active")
      }
    })
    label.classList.add("label-active")
  }

  const addScrollListener = scroll => {
    const hotspots = [...document.querySelectorAll(".svg-target")]
    const explores = [...document.querySelectorAll(".explore_container")]
    const items = [...document.querySelectorAll(".item")]

    if (hotspots.length) {
      hotspots.map(s => {
        //mouse enter the hot spots event listener

        s.addEventListener("click", e => {
          scrollToLabel(e, scroll)
        })
      })
    }

    if (items.length) {
      items.map(item => {
        if (item.dataset?.id) {
          item.addEventListener("click", () => {
            scroll.scrollTo(document.querySelector(`.${item.dataset.id}`), {
              offset: 0,
            })
          })
        }
      })
    }
    if (window.location.pathname.length > 1 && explores.length) {
      explores.map(item => {
        item.addEventListener("click", () => {
          scroll.scrollTo(document.querySelector(".first-section"), {
            offset: -300,
          })
        })
      })
    } else if (explores.length && window.location.pathname.length < 2) {
      explores.map(item => {
        item.addEventListener("click", () => {
          dispatch({ type: "TOGGLE_MENU" })
        })
      })
    }
  }
  useEffect(() => {
    let locomotiveScroll

    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scroll.container),
      ...scroll.options,
    })
    console.log("callbacks", callbacks.pathname)
    // if (prevLocation && prevLocation.pathname !== callbacks.pathname) {
    //   locomotiveScroll.update()
    // }window.addEventListener("scroll", () => {

    // window.addEventListener("scroll", () => {
    //   console.log("height", heightY)
    //   console.log("container y", containerY)
    //   const containerY = document
    //     .querySelector("#___gatsby")
    //     .getBoundingClientRect().y

    //   const heroSection = document.querySelector(".hero_section")
    //   let heightY
    //   if (heroSection) {
    //     heightY = heroSection.getBoundingClientRect().height
    //   }
    //   if (heightY < Math.abs(containerY)) {
    //     document.querySelector("header").style.filter = "invert(1)"
    //   } else {
    //     document.querySelector("header").style.filter = "invert(0)"
    //   }
    // })

    const heroSection = document.querySelector(".hero_section")
    let heightY
    if (heroSection) {
      heightY = heroSection.getBoundingClientRect().height
    }

    addScrollListener(locomotiveScroll)
    // Exposing to the global scope for ease of use.
    window.scroll = locomotiveScroll
    document.documentElement.setAttribute("data-direction", "up")
    const fadeOutElem = document.querySelector(".map_fade-out-elem")
    const map = document.querySelector(".map__svgs")
    locomotiveScroll.on("scroll", func => {
      if (fadeOutElem) {
        if (map) {
          const diff =
            fadeOutElem.getBoundingClientRect().top - window.innerHeight
          const opacity = (diff + 300) / 150

          map.style.opacity = opacity
        }
      }
      // Update `data-direction` with scroll direction.
      if (func.scroll.y < 30) {
        document.documentElement.setAttribute("data-direction", "up")
      } else {
        document.documentElement.setAttribute("data-direction", func.direction)
      }

      if (!window.location.pathname.includes("economic-contribution")) {
        if (func.scroll.y > heightY) {
          document.querySelector("header").classList.add("header-black")
        } else {
          document.querySelector("header").classList.remove("header-black")
        }
      }
    })

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy()
    }
  }, [])

  return null
}

export default Scroll
