export const breakpoints = {
  xs: "640px",
  s: "1024px",
  m: "1024px",
  l: "1368px",
  xl: "1610px",
}

export const typography = {
  type: {
    primary: '"Landsec Brown", "serif"',
    secondary: "FM, Sans-Serif",
  },
  weight: {
    regular: "400",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  size: {},
}

export const color = {
  // Palette
  hero: "#000000",
  foreword: "#4C8B70",
  foreword_bg: "#F1F5F6",
  national_overview_beige: "#F6F6F1",
  national_overview_blue: "#EBF5FF",
  sustainability: "#FEF4EC",
  covid_19: "#EBF5FF",
  map: "#E6F0F4",
  mapdark: "#D5E4EC",
  social_value: "#FDFFEB",
  grey: "#444444",
  lightblue: "#BBDFE5",
  header: {
    uk_overview: {
      color_1: "#66B0E9",
      color_2: "#1A2076",
      color_3: "#C04B52",
    },
    sustain: {
      color_1: "#0022F5",
      color_2: "#61D14F",
      color_3: "#D2CBB5",
    },
    social_value: {
      color_1: "#EEDB62",
      color_2: "#635652",
      color_3: "#28275F",
    },
    covid_19: {
      color_1: "#DA3C2A",
      color_2: "#D0B4ED",
      color_3: "#93A2B6",
    },
    foreword: {
      color_1: "#1B4650",
      color_2: "#884DF6",
      color_3: "#D1F254",
    },
    jobs: {
      color_1: "#659DF6",
      color_2: "#1A2076",
      color_3: "#A52E5A",
    },
  },
  //purples
  lightpurple: "#9A8FA8",
  purple: "#594270",
  darkpurple: "#3D3259",
  pink: "#A97588",
  //browns
  lightbrown: "#D0AA9C",
  brown: "#AF6A5D",
  darkbrown: "#79473D",
  //lights and darks
  darker: "#2a2a28",
  darkest: "#000000",
  lightest: "#ffffff",
  light: "#DEE2DC",
  lighter: "#E6EDF0",
  //reds and oranges
  orange: "#E16F56",
  lightred: "#C95661",
  darkred: "#963246",
  red: "#B73330",
  //blues
}
