import React, { useRef, useEffect, useContext, useState } from "react"

import { animated, useTransition, useChain } from "react-spring"
import styled from "styled-components"
import { navigate } from "gatsby"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { pageMargins } from "../../styles/utils"
import { typography } from "../../styles/variables"
import Arrow from "../../images/svgs/NavigationArrow"

const StyledContents = styled(animated.div)`
  width: 100%;
  background: #f1f5f6;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  ${pageMargins};
  padding-top: 6rem;

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 3rem;
    & a {
      text-decoration: none;
    }
  }
  & .foreword_link {
    height: 20vh;
    min-height: 125px;
    background: black;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: ${typography.type.primary};
    & svg {
      margin-bottom: 0.4rem;
    }
    & h2 {
      color: white;
      font-size: 48px;
      margin-bottom: 10px;
      margin-top: 15px;
    }
    @media only screen and (max-width: 640px) {
      min-height: 105px;
      & h2 {
        font-size: 40px;
        margin-top: 7px;
      }
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      & h2 {
        margin-bottom: 20px;
      }
    }
    @media only screen and (max-width: 330px) {
      min-height: 80px;
      padding-bottom: 1rem;
      & h2 {
        font-size: 25px;
        margint-top: 0;
      }
    }
  }
`

const MobileContents = () => {
  const rowRef = useRef(null)
  const foreWordRef = useRef()
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const [urlPath, setPath] = useState("")
  const { isAnimating } = state
  const foreWordCurrent = !foreWordRef.current
    ? foreWordRef
    : { current: foreWordRef.current }
  const rowCurrent = !rowRef.current ? rowRef : { current: rowRef.current }
  const rows = [
    { text: "Contents", path: "" },
    {
      text: "Landsec contribution overview",
      path: "landsec-contribution-overview",
    },
    { text: "Total economic contribution", path: "economic-contribution" },
    { text: "Our response to Covid-19", path: "covid-19-response" },
    { text: "Spotlight on: Jobs", path: "jobs" },
    {
      text: "Spotlight on: Environmental sustainability",
      path: "sustainability",
    },
    {
      text: "Spotlight on: Social sustainability",
      path: "social-sustainability",
    },
    { text: "landsec", path: "" },
  ]

  const rowAnimation = useTransition(
    isAnimating ? rows : [],
    item => item.text,
    {
      ref: rowRef,
      from: { opacity: 0, transform: "translateX(30px)" },
      enter: { opacity: 1, transform: "translateX(0px)" },
      leave: { opacity: 0, transform: "translateX(30px)" },
      unique: true,
      trail: 50,

      // reverse: !isAnimating,
    }
  )
  const foreword = useTransition(isAnimating, null, {
    ref: foreWordRef,
    from: { opacity: 0, transform: "translateX(20px)", x: 100 },
    enter: { opacity: 1, transform: "translateX(0px)", x: 0 },
    leave: { opacity: 0, transform: "translateX(20px)", x: 100 },
    onDestroyed: () => {
      if (
        (!isAnimating && !urlPath.length) ||
        (!isAnimating && window.location.pathname === `/${urlPath}`)
      ) {
        dispatch({ type: "TOGGLE_MENU" })
      } else if (urlPath.length && window.location.pathname !== `/${urlPath}`) {
        navigate(`/${urlPath}`)
        setPath("")
      }
    },
    // reverse: !isAnimating,
  })

  useChain([foreWordCurrent, rowCurrent], [0, 0.15])

  useEffect(() => {
    dispatch({ type: "TOGGLE_ANIMATING" })
  }, [])

  useEffect(() => {
    if (urlPath.length) {
      dispatch({ type: "TOGGLE_ANIMATING" })
    }
  }, [urlPath])

  return (
    <StyledContents className="mobile_contents">
      {foreword.map(({ item, key, props }) =>
        item ? (
          <animated.div
            onClick={() => setPath("foreword")}
            key={key}
            style={props}
            className="foreword_link"
          >
            <h2>Foreword</h2>
            <Arrow />
          </animated.div>
        ) : null
      )}
      {rowAnimation.map(({ item, key, props: { opacity, transform } }) =>
        item && item.text !== "landsec" ? (
          <animated.div
            key={key}
            style={{ opacity, transform }}
            onClick={() => (item.path.length ? setPath(item.path) : null)}
            className={`contents_row ${
              !item.path.length ? "contents_title" : ""
            }`}
          >
            {item.path.length ? (
              <React.Fragment>
                <h4>{item.text}</h4>
                <Arrow className={`link-arrow-${item.path}`} />
              </React.Fragment>
            ) : (
              <h4 className="menu_title-main">{item.text}</h4>
            )}
          </animated.div>
        ) : item && item.text === "landsec" ? (
          <animated.a
            key={key}
            style={{ opacity, transform }}
            href="https://landsec.com"
          >
            <p className="landsec_link">Return to landsec.com</p>
          </animated.a>
        ) : null
      )}
    </StyledContents>
  )
}

export default MobileContents
