import React, { Fragment, useContext } from "react"
import styled, { css } from "styled-components"
import { remCalc } from "../styles/utils"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"

const lineTransitionTime = "0.3s"

const StyledBurgerMenu = styled.div`
  height: 3rem;
  width: 3rem;
  background: white;
  border-radius: 50%;
  position: relative;
  & .burger-menu {
    position: relative;
    width: ${remCalc(25)};
    height: ${remCalc(13)};
    cursor: pointer;
    outline: none;
    z-index: 21;
    /* -webkit-tap-highlight-color: transparent; */
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    @media only screen and (max-width: 640px) {
      width: 25px;
      height: 13px;
    }
  }

  .burger-menu-lines .line:nth-child(1) {
    top: 0px;
    left: 0;
    transform-origin: left center;
  }

  .burger-menu-lines .line:nth-child(2) {
    top: ${remCalc(2)};
    left: 0;
    transform-origin: left center;
    @media only screen and (max-width: 1024px) {
      top: 2px;
    }
  }

  .burger-menu-lines .line:nth-child(3) {
    top: ${remCalc(3)};
    left: 0;
    transform-origin: left center;
    @media only screen and (max-width: 1024px) {
      top: 4px;
    }
  }

  & .burger-menu-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;

    transition-duration: ${lineTransitionTime};

    & .line {
      position: relative;
      width: 100%;
      height: 2px;
      background-color: black;
      border-radius: 1px;

      transition: all 0.4s ease 0.4s;
      transform: rotate(0deg);
      transform-origin: left center;
    }
  }
  .burger-menu-lines.is-open .line:nth-child(1) {
    transform: rotate(45deg);
    top: ${remCalc(-4)};
    left: ${remCalc(4)};
  }

  .burger-menu-lines.is-open .line:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  .burger-menu-lines.is-open .line:nth-child(3) {
    transform: rotate(-45deg);
    top: ${remCalc(5)};
    left: ${remCalc(4)};
  }

  @media only screen and (max-width: 1024px) {
    display: inline-block;
  }
`

interface NavburgerProps {
  toggleSidebar: boolean
}

const NavBurger: React.FC<NavburgerProps> = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const clickNavBurger = () => {
    if (state.openMenu) {
      dispatch({ type: "TOGGLE_ANIMATING" })
    } else {
      dispatch({ type: "TOGGLE_MENU" })
    }
  }
  return (
    <StyledBurgerMenu className="burger_menu" onClick={clickNavBurger}>
      <div className="burger-menu">
        <div className={`burger-menu-lines ${state.openMenu ? "is-open" : ""}`}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </StyledBurgerMenu>
  )
}

export default NavBurger
