import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={39}
      height={32}
      viewBox="0 0 39 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle r={16} transform="matrix(0 1 1 0 23 16)" fill="#fff" />
      <path
        d="M25.129 20.791l4.079-4.957-4.079-4.957M29.208 15.763H16.6"
        stroke="#000"
      />
    </svg>
  )
}

export default SvgComponent
